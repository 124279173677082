<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col gap-6">
      <PurchaseModalTokenAmountSelector
        v-model="tokenAmountSelected"
        @token-count-updated="tokenCountUpdated"
      />
      <UDivider />
      <PurchaseModalPaymentMethodSelector
        v-model="paymentMethodSelected"
        :hasPaymentAccounts="hasPaymentAccounts"
        :payment-accounts="paymentAccounts"
        :available-payment-methods="availablePaymentMethods"
        :initial-step="initialStep"
      />
    </div>

    <Errorbox :errors="errors" @clear="errors = []" />

    <div class="flex flex-col gap-1 text-sm">
      <div class="flex flex-row items-center justify-between">
        <span>
          Subtotal -
          <span class="font-bold">{{ perTokenValue }}&cent; per token</span>
          :
        </span>
        <span>{{ USDollar.format(subTotal / 100) }}</span>
      </div>
      <div class="flex flex-row items-center justify-between">
        <span>Merchant Processing</span>
        <span>{{ USDollar.format(merchantProcessingFee / 100) }}</span>
      </div>
      <div class="flex flex-row items-center justify-between">
        <span class="font-bold">Total:</span>
        <span class="font-bold">{{ USDollar.format(total / 100) }}</span>
      </div>
    </div>

    <UDivider />

    <span class="text-xs text-cool-300 dark:text-cool-700">
      Your credit card will be securely billed one time without any recurring
      charges or obligations. This charge will appear on your billing statement
      as
      <span class="font-semibold">"Favoritely"</span>
      .
    </span>

    <Button
      v-if="paymentMethodSelected == 'card'"
      theme="primary"
      :disabled="!valid"
      size="lg"
      block
      @click="nextStep"
    >
      <span v-if="hasPaymentAccounts">
        Purchase for {{ USDollar.format(total / 100) }}
      </span>
      <span v-else>Add Credit/Debit Card</span>
    </Button>

    <!-- <Button
      v-if="paymentMethodSelected == 'paypal'"
      theme="secondary"
      :disabled="!valid"
      size="lg"
      @click="nextStep"
    >
      <p class="flex flex-row flex-wrap items-center justify-center gap-1">
        <span>Pay with</span>
        <br class="md:hidden" />
        <img src="/images/payment-paypal-text.png" class="mt-[1px]" />
      </p>
    </Button> -->
  </div>
</template>

<script setup>
  import { useUserStore } from "@/store/user"

  const availablePaymentMethods = inject("availablePaymentMethods")
  const form = inject("form")

  const props = defineProps({
    paymentAccounts: {
      type: Array,
      required: true,
    },
    initialStep: {
      type: String,
      required: true,
    },
  })

  const config = useRuntimeConfig()
  const userStore = useUserStore()
  const toast = useToast()
  const emit = defineEmits(["close", "moveToStep"])
  const perTokenValue = 10
  const tokenAmountSelected = ref(250)
  const merchantProcessingFee = ref(500)
  const paymentMethodSelected = ref("card")
  const errors = ref([])

  // TODO: This will have to change once we have multiple gateways in each environment.
  const defaultGateway = computed(() => {
    if (process.env.NODE_ENV === "development") {
      return "Dev"
    } else {
      return "Nmi"
    }
  })

  const USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  })

  const subTotal = computed(() => {
    return tokenAmountSelected.value * perTokenValue
  })

  const total = computed(() => {
    return subTotal.value + merchantProcessingFee.value
  })

  const valid = computed(() => {
    return (
      tokenAmountSelected.value > 0 && form.data.paymentMethodSelected != null
    )
  })

  const hasPaymentAccounts = computed(() => {
    return props.paymentAccounts.length > 0
  })

  const nextStep = () => {
    if (valid.value) {
      if (hasPaymentAccounts.value) {
        purchaseTokens()
      } else {
        emit("move-to-step", { step: "enter-new-card" })
      }
    }
  }

  const tokenCountUpdated = async (newTokenCount) => {
    tokenAmountSelected.value = newTokenCount
    const response = await $api(
      `${config.public.API_URL}/api/purchases/fetch_merchant_fee`,
      {
        method: "GET",
        query: {
          amount: subTotal.value,
          gateway:
            form.data.paymentMethodSelected?.gateway || defaultGateway.value,
        },
      },
    )

    merchantProcessingFee.value = response.fee
  }
  await tokenCountUpdated(tokenAmountSelected.value)

  const purchaseTokens = async () => {
    const { error } = await useAuthFetch(
      `${config.public.API_URL}/api/purchases/buy_tokens`,
      {
        method: "POST",
        body: JSON.stringify({
          purchase: {
            payment_account_id: form.data.paymentMethodSelected.value,
            token_amount: tokenAmountSelected.value,
          },
        }),
      },
    )

    if (error.value) {
      errors.value = error.value.data.payment
    } else {
      userStore.getData()
      toast.add({ title: "Tokens purchased!" })
      emit("close")
      useShootConfetti()
    }
  }
</script>
